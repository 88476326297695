export const emailTemplate = (title, note, orderId, totalPrice, paymentType, address, email, number, products) => `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>${title}</title>
      <style>
          body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              background-color: #f4f4f4;
          }
          .container {
              width: 100%;
              max-width: 600px;
              margin: 0 auto;
              background-color: #ffffff;
              padding: 20px;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          .header {
              text-align: center;
              padding: 10px 0;
          }
          .header img {
              max-width: 200px;
          }
          .content {
              padding: 20px;
          }
          .order-summary {
              margin-top: 20px;
          }
          .order-summary table {
              width: 100%;
              border-collapse: collapse;
          }
          .order-summary th, .order-summary td {
              padding: 10px;
              border: 1px solid #dddddd;
              text-align: left;
          }
          .order-summary th {
              background-color: #f4f4f4;
          }
      </style>
  </head>
  <body>
      <div class="container">
          <div class="header">
              <img src="https://firebasestorage.googleapis.com/v0/b/bengalina-bd.appspot.com/o/documents%2FbrandImages%2Ffavicon.png?alt=media&token=1d2e7f33-5634-49e3-9dad-3c58af55d6dc" alt="Bengalina Logo">
          </div>
          <div class="content">
              <h1>${note}</h1>
              <p>Order ID: ${orderId}</p>
              <p>Order Total: ${totalPrice} BDT</p>
              <p>Payment Method: ${paymentType}</p>
              <p>Shipping Address: ${address}</p>
              <p>Email Address: ${email}</p>
              <p>Phone Number: ${number}</p>
              <div class="order-summary">
                  <h2>Order Summary</h2>
                  <table>
                      <thead>
                          <tr>
                              <th>Product Image</th>
                              <th>Product Title</th>
                              <th>Quantity</th>
                              <th>Color</th>
                              <th>Measurements</th>
                          </tr>
                      </thead>
                      <tbody>
                          ${products.map(product => `
                              <tr>
                                  <td><img src="${product.productImage}" alt="Product Image" style="max-width: 50px;"></td>
                                  <td>${product.productTitle}</td>
                                  <td>${product.quantity}</td>
                                  <td>${product.color}</td>
                                  <td>${product.measurements}</td>
                              </tr>
                          `).join('')}
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
  </body>
  </html>
`;